import type { DigitalData } from '@dx-ui/config-metrics';

export const digitalData: DigitalData = {
  event: [],
  user: [],
  page: {
    attributes: {
      version: '',
      siteExperience: 'R',
    },
    category: {
      experience: 'Browser',
      primaryCategory: 'Group',
      siteType: 'B',
    },
    pageInfo: {
      pageType: '',
      pageDetail1: '',
      pageDetail2: '',
      pageDetail3: '',
    },
  },
};
