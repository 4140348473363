import * as React from 'react';
import type { Metrics } from './config/ExtendedAdobeTagManager';
import metrics from './config/metrics';

export const MetricsContext = React.createContext<{ metrics: Metrics }>({ metrics: {} as Metrics });
export const MetricsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { api } = metrics;
  return (
    <MetricsContext.Provider value={{ metrics: api as Metrics }}>
      {children}
    </MetricsContext.Provider>
  );
};
