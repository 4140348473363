import { env } from '@dx-ui/framework-env';
import { metricsConfig, createMetrics } from '@dx-ui/config-metrics';
import { digitalData } from './digitalData';
import ExtendedAdobeTagManager from './ExtendedAdobeTagManager';

const config = metricsConfig({
  digitalData,
  debug: !process.env.DISABLE_METRICS_DEBUGGING && /local|test/.test(process.env.APP_ENV || ''),
  Interface: ExtendedAdobeTagManager,
  seedFile: env('TRACKING_SEED_FILE'),
  server: typeof window === 'undefined',
});

export default createMetrics(config);
