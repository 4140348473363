import { env } from '@dx-ui/framework-env';
import type { TFunction } from 'i18next';

/**
 * Hardcoded links are intended to be temporary until live CMS links are pulled in.
 * _id fields are carried over from a baseline query and are solely used to ensure
 * the footer implementation has something unique for the
 * key fields in the li elements.
 */
export const FOOTER_LINKS = (t: TFunction<'common'>) => [
  {
    isNewWindow: true,
    label: t('footer.webAccessibility'),
    adaDescription: `${t('footer.webAccessibility')}. ${t('accessibility.opensNewTab')}`,
    url: 'https://hiltonhonors3.hilton.com/en/support/accessibility.html',
  },
  {
    isNewWindow: true,
    label: t('footer.customerSupport'),
    adaDescription: `${t('footer.customerSupport')}. ${t('accessibility.opensNewTab')}`,
    url: 'https://help.hilton.com/s/',
  },
  {
    isNewWindow: true,
    label: t('footer.globalPrivacyStatement'),
    adaDescription: `${t('footer.globalPrivacyStatement')}. ${t('accessibility.opensNewTab')}`,
    url: 'http://hiltonhonors3.hilton.com/en/policy/global-privacy-statement/index.html',
  },
  {
    isNewWindow: true,
    label: t('footer.siteUsageAgreement'),
    adaDescription: `${t('footer.siteUsageAgreement')}. ${t('accessibility.opensNewTab')}`,
    url: 'http://hiltonhonors3.hilton.com/en/policy/site-usage-agreement/english.html',
  },
  {
    isNewWindow: true,
    label: t('footer.partnerAccommodations'),
    adaDescription: `${t('footer.partnerAccommodations')}. ${t('accessibility.opensNewTab')}`,
    url: `${env('OHW_BASE_URL')}en/p/partner-accommodations-terms/`,
  },
  {
    isNewWindow: true,
    label: t('footer.modernSlaveryAndHumanTrafficking'),
    adaDescription: `${t('footer.modernSlaveryAndHumanTrafficking')}. ${t(
      'accessibility.opensNewTab'
    )}`,
    url: 'https://cr.hilton.com/wp-content/uploads/2021/06/Hilton-Slavery-and-Trafficking-Statement-2020.pdf',
  },
  {
    isNewWindow: true,
    label: t('footer.adChoices'),
    adaDescription: `${t('footer.adChoices')}. ${t('accessibility.opensNewTab')}`,
    url: 'http://optout.aboutads.info/#!/',
  },
];
