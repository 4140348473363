import { env } from '@dx-ui/framework-env';
import { AdobeTagManager } from '@dx-ui/config-metrics';
import { digitalData as initDigitalData } from './digitalData';

const getGoUserType: (packages: string[]) => string = (packages) => {
  const goUserTypes = ['TMH', 'FF', 'OAS', 'ODM', 'GHFT', 'GHFF'];
  if (!packages || !packages.length) {
    return '';
  }
  return packages.filter((p) => goUserTypes.includes(p)).join(',');
};

type RatePlans = {
  ctyhocn: string;
  groupCode: string;
};

export interface CommonDataProps {
  brandCode: string;
  lang: string;
}

export interface SignInPageMetricsProps {
  brandName: string;
  lang: string;
}

export interface ErrorPageMetricsProps {
  errorCode: string;
  errorMessage: string;
}

export interface LoginMetricsProps {
  hhonorsNumber: string;
  tierName: string;
  totalPoints: string;
  packages: string[];
}

export interface GroupSearchPageMetricsProps {
  brandName: string;
  lang: string;
  isEditFlow: boolean;
  ratePlans?: RatePlans[];
}

export interface CreatePageMetricsProps {
  brandName: string;
  lang: string;
  isEditFlow: boolean;
  ratePlans: RatePlans[];
}

export interface PreviewPageMetricsProps {
  brandName: string;
  lang: string;
  isEditFlow: boolean;
  ratePlans: RatePlans[];
}

export interface ConfirmationPageMetricsProps {
  brandName: string;
  lang: string;
  isEditFlow: boolean;
  ratePlans: RatePlans[];
}

export interface CustomPageMetricsProps {
  brandName: string;
  lang: string;
  ratePlans: RatePlans[];
}

export const SocialShareType = {
  FACEBOOK: 'FacebookShare',
  EMAIL: 'EmailShare',
} as const;

export interface SocialShareData {
  type: (typeof SocialShareType)[keyof typeof SocialShareType];
}

export interface FormErrorData {
  formErrors: string | string[];
  formErrorMessages: string | string[];
}

export interface LoginErrorProps {
  type: string;
}

export interface Metrics {
  setLoginEvent: () => void;
  setSignoutEvent: () => void;
  setCommonData: (params: CommonDataProps[]) => void;
  setSignInPageData: (params: SignInPageMetricsProps[]) => void;
  setGroupSearchPageData: (params: GroupSearchPageMetricsProps[]) => void;
  setCreatePageData: (params: CreatePageMetricsProps[]) => void;
  setPreviewPageData: (params: PreviewPageMetricsProps[]) => void;
  setConfirmationPageData: (params: ConfirmationPageMetricsProps[]) => void;
  setSocialShareData: (params: SocialShareData[]) => void;
  setFormErrorData: (params: FormErrorData[]) => void;
  setLoginUserData: (params: LoginMetricsProps[]) => void;
  setCustomGroupPageData: (params: CustomPageMetricsProps[]) => void;
  setErrorPageData: (props: ErrorPageMetricsProps[]) => void;
  setLoginError: (props: LoginErrorProps[]) => void;
}

class ExtendedAdobeTagManager extends AdobeTagManager {
  private setRatePlanDetails(ratePlans: RatePlans[]) {
    ratePlans.forEach((ratePlan, index) => {
      this._set(`product[${index}].productInfo.productID`, ratePlan.ctyhocn);
    });
  }
  // set common variables here that need to be sent in each analytics request
  setCommonData(props: CommonDataProps) {
    const { brandCode, lang } = props;
    const { user } = this.digitalData;
    // resets digitalData
    this.digitalData = JSON.parse(JSON.stringify(initDigitalData));
    this.digitalData.user = user;
    this._resetEvents();
    this._set('page.category.brand', brandCode);
    this._set('page.pageInfo.language', lang);
    this._set('page.pageInfo.pageType', 'AttendeeWebsite');
    this._set('page.pageInfo.destinationURL', encodeURI(document.location.href));
    this._set('page.pageInfo.pageTitle', document.title);
    this._set('page.attributes.version', env('APP_VER'));
  }

  setSignInPageData(props: SignInPageMetricsProps) {
    const { brandName, lang } = props;
    const pageName = `Browser:${lang.toUpperCase()}:${brandName.replace(
      / /g,
      ''
    )}:Group:AttendeeWebsite:SignIn`;
    this._set('page.pageInfo.pageName', pageName);
    this._set('page.pageInfo.pageDetail1', 'SignIn');
    void this.track('AttendeeWebsiteSignInView', null, false);
  }

  setLoginEvent() {
    this.clearAlert();
    this._set('event[0].eventInfo.eventAction', 'signIn');
    void this.track('signedIn', null, false);
  }

  setSignoutEvent() {
    this._resetEvents();
    this._set('user[0].profile[0].profileInfo.profileID', '');
    this._set('user[0].profile[0].profileInfo.pointsBalance', '');
    this._set('user[0].profile[0].profileInfo.rewardsTier', '');
    this._set('user[0].profile[0].attributes.goUserType', '');
    this._set('user[0].profile[0].attributes.loginStatus', 'Logged-out');
  }

  setLoginUserData(props: LoginMetricsProps) {
    const { hhonorsNumber, tierName, totalPoints, packages } = props;
    this._set('user[0].profile[0].profileInfo.pointsBalance', totalPoints);
    this._set('user[0].profile[0].profileInfo.profileID', hhonorsNumber);
    this._set('user[0].profile[0].profileInfo.rewardsTier', tierName);
    this._set('user[0].profile[0].attributes.goUserType', getGoUserType(packages));
    this._set('user[0].profile[0].attributes.loginStatus', 'Logged-in');
  }

  setGroupSearchPageData(props: GroupSearchPageMetricsProps) {
    const { brandName, lang, ratePlans, isEditFlow } = props;
    const pageDetail1 = ratePlans?.length ? 'GroupEventDetails' : 'GroupSearch';
    const pageName = `Browser:${lang.toUpperCase()}:${brandName.replace(
      / /g,
      ''
    )}:Group:AttendeeWebsite:${pageDetail1}${isEditFlow ? ':Manage' : ''}`;
    this._set('page.pageInfo.pageName', pageName);
    this._set('page.pageInfo.pageDetail1', 'GroupEventDetails');
    if (isEditFlow) this._set('page.pageInfo.pageDetail2', 'Manage');
    if (ratePlans?.length) this.setRatePlanDetails(ratePlans);
    void this.track('AttendeeWebsiteGroupSearchView', null, false);
  }

  setCreatePageData(props: CreatePageMetricsProps) {
    const { brandName, lang, ratePlans, isEditFlow } = props;
    const pageName = `Browser:${lang.toUpperCase()}:${brandName.replace(
      / /g,
      ''
    )}:Group:AttendeeWebsite:CreatePage${isEditFlow ? ':Modify' : ''}`;
    this._set('page.pageInfo.pageName', pageName);
    this._set('page.pageInfo.pageDetail1', 'CreatePage');
    if (isEditFlow) this._set('page.pageInfo.pageDetail2', 'Modify');
    if (ratePlans?.length) this.setRatePlanDetails(ratePlans);
    void this.track('AttendeeWebsiteCreatePageView', null, false);
  }

  setPreviewPageData(props: PreviewPageMetricsProps) {
    const { brandName, lang, ratePlans, isEditFlow } = props;
    const pageName = `Browser:${lang.toUpperCase()}:${brandName.replace(
      / /g,
      ''
    )}:Group:AttendeeWebsite:EventPreview${isEditFlow ? ':Modify' : ''}`;
    this._set('page.pageInfo.pageName', pageName);
    this._set('page.pageInfo.pageDetail1', 'EventPreview');
    if (isEditFlow) this._set('page.pageInfo.pageDetail2', 'Modify');
    if (ratePlans?.length) this.setRatePlanDetails(ratePlans);
    void this.track('AttendeeWebsitePreviewView', null, false);
  }

  setConfirmationPageData(props: ConfirmationPageMetricsProps) {
    const { brandName, lang, ratePlans, isEditFlow } = props;
    const pageName = `Browser:${lang.toUpperCase()}:${brandName.replace(
      / /g,
      ''
    )}:Group:AttendeeWebsite:EventConfirmation${isEditFlow ? ':Modify' : ''}`;
    this._set('page.pageInfo.pageName', pageName);
    this._set('page.pageInfo.pageDetail1', 'EventConfirmation');
    if (isEditFlow) this._set('page.pageInfo.pageDetail2', 'Modify');
    if (ratePlans?.length) this.setRatePlanDetails(ratePlans);
    void this.track('AttendeeWebsiteEventConfirmationView', null, false);
  }

  setSocialShareData(props: SocialShareData) {
    const actionDetail = `Button:Group:${
      props.type === SocialShareType.EMAIL ? 'EmailShare' : 'FacebookShare'
    }`;
    const eventAction = props.type === SocialShareType.EMAIL ? 'GroupEmailShare' : 'GroupFBShare';
    const dcrType = props.type === SocialShareType.EMAIL ? 'groupEmailShare' : 'groupFBShareClick';
    this._resetEvents();
    this.setEventInfo(eventAction);
    this._set('page.attributes.actionDetail', actionDetail);
    void this.track(dcrType, null, false);
  }

  setFormErrorData(props: FormErrorData) {
    const fields = props.formErrors;
    const messages = props.formErrorMessages;
    const formError = Array.isArray(fields) ? fields.join('|') : fields;
    const formErrorMessage = Array.isArray(messages) ? messages.join('|') : messages;
    this._set('page.pageInfo.formError', formError);
    this._set('page.pageInfo.formErrorMessage', formErrorMessage);
    this._set('page.attributes.actionDetail', 'formError');
    void this.track('formErrorEvent', null, false);
  }

  setCustomGroupPageData(props: CustomPageMetricsProps) {
    const { brandName, lang, ratePlans } = props;
    const pageName = `Browser:${lang.toUpperCase()}:${brandName.replace(
      / /g,
      ''
    )}:Group:AttendeeWebsite:Site:${ratePlans?.[0]?.ctyhocn}:${ratePlans?.[0]?.groupCode}`;
    this._set('page.pageInfo.pageName', pageName);
    this._set('page.pageInfo.pageDetail1', 'Site');
    this.setRatePlanDetails(ratePlans);
    void this.track('AttendeeWebsiteView', null, false);
  }

  setErrorPageData(props: ErrorPageMetricsProps) {
    const { errorCode, errorMessage } = props;
    this._set('page.attributes.alertTextMessage', errorMessage);
    this._set('page.pageInfo.error', errorCode);
    this._set('event[0].eventInfo.eventAction', 'AttendeeWebsiteError');
    void this.track('AttendeeWebsiteError', null, false);
  }

  public clearAlert() {
    this._unset('page.attributes.alertTextMessage');
    this._unset('page.attributes.alertCode');
  }

  public setLoginError({ type }: LoginErrorProps) {
    let alertMessage = '';
    switch (type) {
      case 'invalid_grant':
        alertMessage =
          'Your login didn’t match our records. Please try again. Be careful: too many attempts will lock your account.';
        break;
      case 'invalid_entry':
        alertMessage = 'Forgetting something? We need your username and password to login.';
        break;
      case 'invalid_recaptcha':
        alertMessage = "Please confirm you're not a robot to continue.";
        break;
    }
    this._set('page.attributes.alertCode', type);
    this._set('page.attributes.alertTextMessage', alertMessage);
    this._set('event[0].eventInfo.eventAction', 'HonorsLoginError');
    void this.track('HonorsLoginError', null, false);
  }

  /**
   * @method _addPageBottom
   * Overriding _addPageBottom to prevent running server side
   */
  _addPageBottom() {
    if (typeof window !== 'undefined' && document && document.body) {
      const script = document.createElement('script');
      // Lets add to page so Adobe consultant knows we've added the pageBottom() call.
      const scriptContent = `
        "use strict";
        var _satellite = window._satellite;
        if (_satellite) {
          document.write = document.body.appendChild
          _satellite.pageBottom();
        }
      `;

      script.text = scriptContent;
      return document.body.appendChild(script);
    }
    return null;
  }
}

export default ExtendedAdobeTagManager;
