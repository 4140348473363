import * as React from 'react';

export type CorpUserInfo = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
};

export const CorpUserInfoContext = React.createContext<CorpUserInfo>({
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
});

export const CorpUserInfoProvider: React.FC<
  React.PropsWithChildren<{ corpUserInfo: CorpUserInfo }>
> = ({ corpUserInfo, children }) => (
  <CorpUserInfoContext.Provider value={corpUserInfo}>{children}</CorpUserInfoContext.Provider>
);
